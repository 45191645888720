/* eslint-disable max-len */
import { Component, inject, OnInit } from '@angular/core';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { LobbyUser } from '@dc-core/dc-gamelogic/game-interfaces';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { ModalController, ModalOptions, NavParams } from '@ionic/angular/standalone';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { JoyrideService } from 'src/providers/ngx-joyride/src/lib/services/joyride.service';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-invite-game-request-dialog',
    templateUrl: 'invite-game-request.html',
    styleUrls: ['invite-game-request.scss'],
})
export class InviteGameRequestDialogComponent implements OnInit {
    user: LobbyUser;

    private modalController: ModalController = inject(ModalController);

    constructor(
        private navParams: NavParams,
        private readonly joyrideService: JoyrideService,
        private _dcInvites: InviteCollectionService,
        private _lobbySettingsService: LobbySettingsCollectionService,
        public auth: AuthService
    ) {}

    ngOnInit() {
        this.user = this.navParams.get('user');

        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeProfileDialog)) ?? true;
        if (showTour) {
            this.showTour();
            localStorage.setItem(LocalStorageKey.firstTimeProfileDialog, JSON.stringify(false));
        }
    }

    public showTour(index = null) {
        let steps = ['firstStep'];
        if (index !== null) {
            steps = [steps[index]];
        }

        setTimeout(() => {
            this.joyrideService.startTour({
                steps: steps,
                showPrevButton: false,
                themeColor: '#444',
            });
        }, 500);
    }

    public close(): void {
        this.modalController.dismiss(false);
    }

    public trySendInvite(): void {
        if (this._dcInvites.currentOutgoingInvite()) {
            this.modalController
                .create({
                    component: PromptDialogComponent,
                    componentProps: {
                        title: $localize`:@@SEND_INVITE:Send invite`,
                        text: $localize`:@@CANCEL_YOUR_ACTIVE_INVITE:If you send this invite, you will cancel your other active invitation.`,
                        cancelText: $localize`:@@CANCEL:Cancel`,
                        confirmText: $localize`:@@CONFIRM:Confirm`,
                        confirmColor: 'orange',
                    } as AlertPayload,
                    cssClass: 'auto-height',
                    showBackdrop: true,
                    backdropDismiss: false,
                } as ModalOptions)
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this._dcInvites.removeOutgoingInvites();
                            this._lobbySettingsService.removeLobbyGame();
                            this.modalController.dismiss(true);
                        }
                    });
                });
        } else {
            this._lobbySettingsService.removeLobbyGame();
            this.modalController.dismiss(true);
        }
    }

    public sendInvite(): void {
        this.modalController.dismiss(true);
    }
}
